<template>
  <div>
    <svg
      v-if="$theme == 1"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.77778 13.1696H19.2222C19.4285 13.1696 19.6263 13.0906 19.7722 12.9499C19.9181 12.8093 20 12.6185 20 12.4196C20 12.2207 19.9181 12.0299 19.7722 11.8893C19.6263 11.7486 19.4285 11.6696 19.2222 11.6696H6.77778C6.5715 11.6696 6.37367 11.7486 6.22781 11.8893C6.08194 12.0299 6 12.2207 6 12.4196C6 12.6185 6.08194 12.8093 6.22781 12.9499C6.37367 13.0906 6.5715 13.1696 6.77778 13.1696Z"
        fill="#494949"
      />
    </svg>
    <svg
      v-if="$theme == 2"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1335_844)">
        <path
          d="M5.5 12.0001H19.5"
          stroke="#494949"
          stroke-width="2"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1335_844">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 9.15527e-05)"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      v-if="$theme == 3"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2 12.5938H5.8C5.58783 12.5938 5.38434 12.4885 5.23431 12.3009C5.08429 12.1134 5 11.8591 5 11.5938C5 11.3286 5.08429 11.0743 5.23431 10.8867C5.38434 10.6992 5.58783 10.5938 5.8 10.5938H20.2C20.4122 10.5938 20.6157 10.6992 20.7657 10.8867C20.9157 11.0743 21 11.3286 21 11.5938C21 11.8591 20.9157 12.1134 20.7657 12.3009C20.6157 12.4885 20.4122 12.5938 20.2 12.5938Z"
        fill="#494949"
      />
    </svg>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    fillColor: {
      type: String,
    },
  },
});
</script>
